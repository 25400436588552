import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'visits',
    loadChildren: () => import('./visits/visits.module').then( m => m.VisitsPageModule)
  },
  {
    path: 'create-visit',
    loadChildren: () => import('./create-visit/create-visit.module').then( m => m.CreateVisitPageModule)
  },
  {
    path: 'visit/:id',
    loadChildren: () => import('./visit/visit.module').then( m => m.VisitPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'announcement/:id',
    loadChildren: () => import('./announcement/announcement.module').then( m => m.AnnouncementPageModule)
  },
  {
    path: 'create-announcement',
    loadChildren: () => import('./create-announcement/create-announcement.module').then( m => m.CreateAnnouncementPageModule)
  },
  /*{
    path: 'announcements',
    loadChildren: () => import('./announcements/announcements.module').then( m => m.AnnouncementsPageModule)
  },*/
  {
    path: 'announcements',
    loadChildren: () => import('./announcements/announcements.module').then( m => m.AnnouncementsPageModule)
  },
  {
    path: 'reservations',
    loadChildren: () => import('./reservations/reservations.module').then( m => m.ReservationsPageModule)
  },
  {
    path: 'common_areas',
    loadChildren: () => import('./common-areas/common-areas.module').then( m => m.CommonAreasPageModule)
  },
  {
    path: 'create-reservation',
    loadChildren: () => import('./create-reservation/create-reservation.module').then( m => m.CreateReservationPageModule)
  },
  {
    path: 'listing',
    loadChildren: () => import('./listing/listing.module').then( m => m.ListingPageModule)
  },
  {
    path: 'listings',
    loadChildren: () => import('./listings/listings.module').then( m => m.ListingsPageModule)
  },
  {
    path: 'create-listing',
    loadChildren: () => import('./create-listing/create-listing.module').then( m => m.CreateListingPageModule)
  },
  {
    path: 'directory',
    loadChildren: () => import('./directory/directory.module').then( m => m.DirectoryPageModule)
  },
  {
    path: 'directories',
    loadChildren: () => import('./directories/directories.module').then( m => m.DirectoriesPageModule)
  },
  {
    path: 'create-directory',
    loadChildren: () => import('./create-directory/create-directory.module').then( m => m.CreateDirectoryPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'survey/:id',
    loadChildren: () => import('./survey/survey.module').then( m => m.SurveyPageModule)
  },
  {
    path: 'surveys',
    loadChildren: () => import('./surveys/surveys.module').then( m => m.SurveysPageModule)
  },
  {
    path: 'documents/:id',
    loadChildren: () => import('./documents/documents.module').then( m => m.DocumentsPageModule)
  },
  {
    path: 'document/:id',
    loadChildren: () => import('./document/document.module').then( m => m.DocumentPageModule)
  },
  {
    path: 'cal-modal-page',
    loadChildren: () => import('./cal-modal/cal-modal.module').then( m => m.CalModalPageModule)
  },
  {
    path: 'cal-modal',
    loadChildren: () => import('./cal-modal/cal-modal.module').then( m => m.CalModalPageModule)
  },
  {
    path: 'incomes',
    loadChildren: () => import('./incomes/incomes.module').then( m => m.IncomesPageModule)
  },
  {
    path: 'income_outcome',
    loadChildren: () => import('./income-outcome/income_outcome.module').then( m => m.IncomeOutcomePageModule)
  },
  {
    path: 'upload_invoice/:id',
    loadChildren: () => import('./invoice/invoice.module').then( m => m.InvoicePageModule)
  },
  {
    path: 'occupants',
    loadChildren: () => import('./occupant/occupant.module').then( m => m.OccupantPageModule)
  },
  {
    path: 'create_occupant',
    loadChildren: () => import('./create-occupant/create-occupant.module').then( m => m.CreateOccupantPageModule)
  },
  {
    path: 'update_occupant/:id',
    loadChildren: () => import('./update-occupant/update-occupant.module').then( m => m.UpdateOccupantPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  /*{
    path: 'correspondences',
    loadChildren: () => import('./correspondences/correspondences.module').then( m => m.CorrespondencesPageModule)
  },*/
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'common-areas',
    loadChildren: () => import('./common-areas/common-areas.module').then( m => m.CommonAreasPageModule)
  },
  {
    path: 'user-change-password',
    loadChildren: () => import('./user-change-password/user-change-password.module').then( m => m.UserChangePasswordPageModule)
  },  {
    path: 'pdf-viewer',
    loadChildren: () => import('./pdf-viewer/pdf-viewer.module').then( m => m.PdfViewerPageModule)
  },
  {
    path: 'about-client',
    loadChildren: () => import('./about-client/about-client.module').then( m => m.AboutClientPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
