import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule} from '@angular/forms';
import { IonBottomSheetModule } from 'ion-bottom-sheet';
import { IonicModule } from '@ionic/angular';
import { IncomeOutcomeModalPage } from './income_outcome_modal.page';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    IonBottomSheetModule,
    TranslateModule.forChild()
  ],
  declarations: [IncomeOutcomeModalPage]
})
export class IncomeOutcomeModalPageModule {}
