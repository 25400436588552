import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private apiEndpoint = environment.apiEndpoint;
  constructor(
    private httpClient: HttpClient,
  ) {}

  login = (credentials) => {
    return this.httpClient.post(`${this.apiEndpoint}login`, credentials).toPromise();
  };

  register = (data) => {
    return this.httpClient.post(`${this.apiEndpoint}register`, data).toPromise();
  };

  updatePushToken = (userId, push_token) => {
    return this.httpClient.put(`${this.apiEndpoint}user/updatePushToken/${userId}`, {push_token}).toPromise();
  }

  // Recover password section:
  response = (data) =>{
    return this.httpClient.post(`${this.apiEndpoint}create`,data).toPromise();
  }
  findToken = (token) =>{
    return this.httpClient.get(`${this.apiEndpoint}find/${token}`).toPromise();
  }
  savePassword = (data) =>{
    return this.httpClient.post(`${this.apiEndpoint}reset`,data).toPromise();
  }
}