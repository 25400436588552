import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-income_outcome_modal',
  templateUrl: './income_outcome_modal.page.html',
  styleUrls: ['./income_outcome_modal.page.scss'],
})
export class IncomeOutcomeModalPage implements OnInit {

  @Input() selectedMonth: string;
  @Input() selectedYear: string;

  constructor(
    public modalController: ModalController,
  ) {}

  ngOnInit() {
    if(this.selectedMonth==null || this.selectedYear==null){
      this.selectedMonth="01";
      this.selectedYear="2021";
    }
  }

  dismiss() {
    let self = this;
    self.modalController.dismiss({
      'dismissed': true
    });
  }

  filter(){
    let self = this;
    let data = {
      month: self.selectedMonth,
      year: self.selectedYear
    }
    self.modalController.dismiss(data);
  }

  clear(){
    let self = this;
    self.selectedYear=null;
    self.selectedMonth=null;
    self.modalController.dismiss({
      'dismissed': true
    });
  }

  set(){
    this.selectedMonth="09";
    this.selectedYear="2021"
  }

  unset(){
    this.selectedYear=null;
    this.selectedMonth=null;
  }

}
