import { ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FcmService } from './services/fcm.service';
import { SharedService } from './services/shared.service';
import { UtilsService } from './services/utils.service';
import { LanguageService } from './services/LanguageService';
import { APIResponse } from './interfaces/APIResponse';
import { UsersService } from './services/users.service';
import { PermissionService } from './services/PermissionService';
import { BottomSheetCategoriesService } from './services/BottomSheetCategoriesService';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [];
  public user: any;
  public showLeftMenu: Boolean = false
  loggedEvent: any
  permissionsData : any

  constructor(
    private fcmService: FcmService,
    loggedEvent: SharedService,
    private translate: TranslateService,
    private utilsService: UtilsService,
    private languageService: LanguageService,
    private usersService: UsersService,
    private permissionService: PermissionService,
    private cdr: ChangeDetectorRef,
    private bottomService: BottomSheetCategoriesService
  ) {
    this.loggedEvent = loggedEvent;
    this.user = this.utilsService.getUserFromLocalStorage();
    this.fcmService.initPush();

    if(this.user != null){
      this.showLeftMenu = true;
    }

    this.translate.onLangChange.subscribe((event: Event) => {
      this.setAppPages();
    });

    this.languageService.languageChanged.subscribe((language: string) => {
      this.translate.use(language);
    });

    this.permissionService.permissionsLoaded.subscribe((data: any) => {
      this.updateAppPages(data)
      console.log("permisos en el app component", data)
    });
  }

  async getModulePermissions() {
    const { data, message, success } = await this.usersService.getModulePermissions() as APIResponse;
  
    const modules = {
      'incomes_outcomes': 'showPaymentsOutcomes',
      'reservations': 'showReservations',
      'control_accesos': 'showVisits',
      'payments': 'showPayments',
      'announcements': 'showAnnouncements',
      'documents': 'showDocuments',
      'surveys': 'showSurveys',
    };
  
    Object.entries(modules).forEach(([name, propertyName]) => {
      this[propertyName] = data.find(row => row.name === name).status;
    });
  
    this.permissionService.permissionsChanged(data);
  }

  ngOnInit() {
    this.loggedEvent.getEmittedValue().subscribe(value => {
      this.showLeftMenu = value
    });
    this.translate.setDefaultLang('en'); // add this

    this.translate.get('TITLES.HOME').subscribe(() => {
      this.setAppPages();
    });
  }

  ionViewDidEnter() {
    this.user = this.utilsService.getUserFromLocalStorage();
    console.log(this.user);
  }

  async switchLanguage($event) {
    const value = $event.target.value;
    const { data, message, success } = await this.usersService.setRemoteLanguage(value) as APIResponse;
    this.translate.use(value);
  }

  setAppPages(){
    this.appPages = [
      { title: this.translate.instant('TITLES.HOME'), url: '/home', icon: 'home' },
      { title: this.translate.instant('TITLES.VISITS'), url: '/visits', icon: 'qr-code' },
      { title: this.translate.instant('TITLES.INCOMES'), url: '/incomes', icon: 'card' },
      { title: this.translate.instant('TITLES.FINANCE'), url: '/income_outcome', icon: 'wallet' },
      { title: this.translate.instant('TITLES.RESERVATIONS'), url: '/common-areas', icon: 'calendar' },
      { title: this.translate.instant('TITLES.ANNOUNCEMENTS'), url: '/announcements', icon: 'megaphone' },
      { title: this.translate.instant('TITLES.DIRECTORY'), url: '/directories', icon: 'id-card' },
      { title: this.translate.instant('TITLES.DOCUMENTS'), url: '/documents', icon: 'documents', action: 'openDocumentsCategory' }
    ];
    if (this.user?.user?.role?.id !== 6) {
      this.appPages.push(
        { title: this.translate.instant('TITLES.OCCUPANTS'), url: '/occupants', icon: 'person' },
        { title: this.translate.instant('TITLES.SURVEYS'), url: '/surveys', icon: 'bar-chart' },
      );
    }
    this.appPages.push({ title: this.translate.instant('TITLES.PROFILE'), url: '/login', icon: 'person' });
  }

  updateAppPages(data) {
    this.permissionsData = data
  
    setTimeout(() => {
      const pagesToUpdate = [
        { name: 'common_areas', url: '/common-areas' },
        { name: 'control_accesos', url: '/visits' },
        { name: 'payments', url: '/incomes' },
        { name: 'incomes_outcomes', url: '/income_outcome' },
        { name: 'reservations', url: '/reservations' },
        { name: 'announcements', url: '/announcements' },
        { name: 'documents', url: '/documents' },
        { name: 'directory', url: '/directories' },
        { name: 'surveys', url: '/surveys' }
      ];
    
      pagesToUpdate.forEach(page => {
        const enablePage = data.find(row => row.name === page.name)?.status;
        if (!enablePage) {
          const pageIndex = this.appPages.findIndex(row => row.url === page.url);
          if (pageIndex !== -1) {
            this.appPages.splice(pageIndex, 1);
          }
        }
      });

      this.cdr.detectChanges();
    }, 500);
    
  }

  openDocumentsCategory(){
    this.bottomService.open()
  }

  logout() {
    this.utilsService.logout();
    this.loggedEvent.logoutEvent();
    this.user = {};
  }
}
