import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { ToastController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { UtilsService } from './utils.service';
 
@Injectable({
  providedIn: 'root'
})
export class FcmService {
  public user: any = null;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastController: ToastController,
    private utilsService: UtilsService,
  ) { }
 
  initPush() {
    this.user = this.utilsService.getUserFromLocalStorage();
    if (Capacitor.getPlatform() !== 'web') {
      this.registerPush();
    }
  }
 
  private registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });
 
    PushNotifications.addListener(
      'registration',
      (token: Token) => {
        console.log('********************* My token: ' + JSON.stringify(token));
        this.authenticationService.updatePushToken(this.user.user.id, token.value).then(response => console.log(response));
      }
    );
 
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });
 
    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
        if (notification.data.notification_type === 'visit_arrived' || notification.data.notification_type === 'visit_left') {
          // this.router.navigateByUrl(`/visit/${notification.data.visit_id}`);
          const toast = await this.toastController.create({
            message: notification.body,
            duration: 4000,
            color: 'default',
            buttons: [
              {
                side: 'start',
                icon: 'star',
                text: 'Ir',
                handler: () => {
                  this.router.navigateByUrl(`/visit/${notification.data.visit_id}`);
                }
              }
            ]
          });
          toast.present();
        }
      }
    );
 
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        console.log('Action performed: ' + JSON.stringify(notification.notification));
        if (data.notification_type === 'visit_arrived' || data.notification_type === 'visit_left') {
          this.router.navigateByUrl(`/visit/${data.visit_id}`);
        }
      }
    );
  }
}