import {Component, Injectable,Input,Output,EventEmitter} from '@angular/core';

@Injectable()
export class SharedService {
  @Output() action: EventEmitter<any> = new EventEmitter();
  
   loginEvent() {
     this.action.emit(true);
   }

   logoutEvent() {
    this.action.emit(false);
    }

   getEmittedValue() {
     return this.action;
   }

} 