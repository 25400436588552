import { Injectable } from "@angular/core";
import * as moment from 'moment';
import { AlertController, LoadingController, ToastController } from "@ionic/angular";
import { UsersService } from "./users.service";
import { APIResponse } from "../interfaces/APIResponse";
import { Router } from "@angular/router";


@Injectable({
  providedIn: 'root'
})

export class UtilsService {
  public loading: any;
  constructor(
    private alertController: AlertController,
    private router: Router,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private usersService: UsersService,
  ) {}
  
  calculateInitals = (string) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...string.matchAll(rgx)] || [];

    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();

    return initials;
  }

  dateToOurDate = (date, format = 'ddd, DD/MM/YYYY') => {
    return moment(date).format(format);
  }

  async presentToast(message, color = 'light') {
    const toast = await this.toastController.create({
      message,
      duration: 3000,
      color
    });
    toast.present();
  }

  async presentLoading(message) {
    this.loading = await this.loadingController.create({
      message
    });
    await this.loading.present();
    const { role, data } = await this.loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

  stopLoading() {
    this.loading.dismiss();
  }

  public calculateStars = (rating, maxStars = 5) => {
    let response = new Array(maxStars).fill(false);
    for (let i = 0; i < rating ; i++) {
      response[i] = true;
    }
    return response;
  }

  public getCurrentUserAddress = async (userId) => {
    const currentAddress = JSON.parse(window.localStorage.getItem('col_selected_address') || '{}');
    if (currentAddress?.id) {
      return currentAddress;
    } else {
      const { data, message, success } = await this.usersService.getUserAddresses(userId) as APIResponse;
      if (!success) {
        this.presentToast(message, 'danger');
        return;
      }
      JSON.stringify(data[0]) && window.localStorage.setItem('col_selected_address', JSON.stringify(data[0]));
      return data[0];
    }

  }

  public logout = async () => {
    const alert = await this.alertController.create({
      header: '¿Está seguro de salir?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
        },
        {
          text: 'Sí',
          role: 'confirm',
          handler: () => {
            window.localStorage.removeItem('col_selected_address');
            window.localStorage.removeItem('col_user');
            this.router.navigateByUrl('/login');
            location.reload();
          },
        },
      ],
    });
    await alert.present();
  }

  public getUserFromLocalStorage = () => {
    if (!window.localStorage.getItem('col_user')) {
      this.router.navigateByUrl('/login');
      return;
    }
    return JSON.parse(window.localStorage.getItem('col_user') || null);
  }
}