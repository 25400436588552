import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private url = environment.apiEndpoint;

  constructor(
    private httpClient: HttpClient
  ) { }

  getBOotstrap = () => {
    return this.httpClient.get(`${this.url}bootstrap`).toPromise();
  }

  getUserById = (id) => {
    return this.httpClient.get(`${this.url}user/${id}`).toPromise();
  }

  deleteTenant = (id) => {
    return this.httpClient.delete(`${this.url}user/${id}`).toPromise();
  }

  getUserAddresses = (userId) => {
    if (!userId) return;
    return this.httpClient.get(`${this.url}user_addresses/${userId}`).toPromise();
  }

  setRemoteLanguage = (language) => {
    return this.httpClient.put(`${this.url}update_language`, { language }).toPromise();
  }

  getModulePermissions = () => {
    return this.httpClient.get(`${this.url}module_permissions`).toPromise();
  }

  changePassword(data){
    return this.httpClient.post(`${this.url}user/change_password`, data).toPromise();
    
  }
}
