import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  permissionsLoaded: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  permissionsChanged(data : any) {
    this.permissionsLoaded.emit(data);
  }
}