import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BottomSheetCategoriesService {
  openBottomSheet: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  open() {
    this.openBottomSheet.emit();
  }
}